<template>
  <svg
    class="v-svg-plus-in-circle"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <rect
        height="22"
        rx="11"
        stroke="currentColor"
        stroke-width="2"
        width="22"
        x="1"
        y="1"
      />
      <path
        d="M15.3577 15.4998L8.2866 8.42875"
        stroke="currentColor"
        stroke-linecap="square"
        stroke-width="2"
      />
      <path
        d="M8.35742 15.5L15.4285 8.42893"
        stroke="currentColor"
        stroke-linecap="square"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<style lang="scss">
.v-svg-plus-in-circle {
  fill: none;
}
</style>
