<template>
  <template v-if="withPreview">
    <div class="v-menu-description-products--with-preview v-d-flex v-flex-column">
      <menu-product-ingredient
        v-for="ingredient in productInList.IngredientsSet"
        :key="`${productInList.ID}-${ingredient.ID}`"
        hide-weight
        :ingredient="ingredient"
        :product-in-list="productInList"
      />
    </div>
  </template>
  <template v-else>
    <template v-if="showTooltip">
      <common-popover-menu
        v-for="(ingredient, index) in productInList.IngredientsSet"
        :key="`${productInList.ID}-${ingredient.ID}`"
        :delay="300"
        :ignore-timer="false"
        :show-on-click="appConfig.VueSettingsPreRun.MenuItemDescriptionUseClick"
        placement="top"
      >
        <template #hoverable>
          <span
            class="v-popper-hoverable-item"
            v-html="sanitize(ingredient.Name)"
          />
          <span
            v-if="index < productInList.IngredientsSet.length - 1"
            v-html="', '"
          />
        </template>
        <template #content>
          <menu-product-ingredient
            :ingredient="ingredient"
            :product-in-list="productInList"
          />
        </template>
      </common-popover-menu>
    </template>
    <template v-else>
      <arora-nuxt-link
        v-for="(ingredient, index) in productInList.IngredientsSet"
        :key="`${productInList.ID}-${ingredient.ID}`"
        class-name="v-ingredient-link"
        :href="productInList.Link"
        :label="sanitize(ingredient.Name)"
      >
        <span
          class="v-ingredient-link__name"
          v-html="sanitize(ingredient.Name)"
        />
        <span
          v-if="index < productInList.IngredientsSet.length - 1"
          v-html="', '"
        />
      </arora-nuxt-link>
    </template>
  </template>
</template>

<script setup lang="ts">
import type { ProductInList } from '~types/menuStore'

withDefaults(
  defineProps<{
    productInList: ProductInList
    withPreview?: boolean
    showTooltip?: boolean
  }>(),
  {
    showTooltip: true
  }
)

const appConfig = useAppConfig()

const { sanitize } = useI18nSanitized()
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-menu-description-products {
  font-size: 1rem;

  .v-popper {
    display: inline-block;
    white-space: break-spaces;
    cursor: pointer;
    @include mixins.trans;

    .v-popper-hoverable-item {
      text-decoration: underline;
      transition: inherit;

      &:hover {
        color: variables.$PrimaryBackgroundColor;
      }
    }
  }

  &--with-preview {
    gap: 5px;

    .v-menu-ingredient {
      padding: 12px 14px;
      border-radius: variables.$BorderRadius;
      box-shadow: variables.$CardShadow;

      &__info {
        gap: 5px;
      }

      &__description {
        color: variables.$BodyTextColorLight;
      }

      &__lower_part {
        &-price {
          font-weight: 500;
        }

        &-link {
          font-weight: 600;
        }

        &-link-arrow-next {
          display: block;
        }
      }
    }
  }
}

.v-menu-ingredient {
  &__lower_part-link-arrow-next {
    display: none;
  }
}

.v-ingredient-link {
  &__name {
    text-decoration: underline;
  }
}
</style>
